<template>
  <div>
    <div class="header_min top0">
      <div class="wrap header_min_box">
        <router-link to="./">
          <div class="fl logo">
            <img src="../../assets/image/funuo.png" alt />
            孚诺大药房
          </div>
        </router-link>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {}
};
</script>
<style lang="less" scoped>
@import "../../assets/less/header";
.top0 {
  top: 0 !important;
}
.header_min {
  margin-top: 0px;
}
</style>
