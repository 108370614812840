<template>
	<div>
		<div class="bgw">
			<landHeader class="bgw">
				<div class="bgw land_hader_top_min">
					<i class="fl"></i>
					<div class="fl logo htm1">欢迎登录</div>
				</div>
			</landHeader>
		</div>
		<div class="mt120 land_h1">
			<div class="wrap">
				<div class="land_content">
					<div class="fl">
						<img src="../../assets/image/Signin_img@2x.png" alt />
					</div>
					<div class="fr land_form">
						<h3>用户登录</h3>
						<p class="land-phone">
							<label for="phone"></label>
							<input v-model="phone" maxlength="11" placeholder="请输入账号" type="text" id="phone" />
						</p>
						<p class="land-txt">{{phoneErr}}</p>
						<p class="land-password">
							<label for="password"></label>
							<input v-model="password" maxlength="12" placeholder="请输入密码" type="password" id="password" />
						</p>
						<p class="land-txt">{{passwordErr}}</p>
						<button @click="sendChecking()" class="land-send">登录</button>
						<p class="land-go">
							<router-link to="./forgetPassword">忘记密码</router-link>
							<span @click="GoLogon()">
								<a href="#">免费注册</a>
							</span>
							<!-- <span @click="GoLogon()" style="color:#38EEC1;">免费注册</span> -->
						</p>
						<p @click="treatyTab()" class="agreeOn">
							登录即代表您同意
							<span>《孚诺大药房平台协议》</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 登录协议 -->
		<div class="land_treaty" v-show="treaty_show == 1" @click="treaty_show = 0" @mousewheel.prevent>
			<div @click.stop class="l_t_txt">
				<p>
					孚诺大药房平台协议
					<img @click="treaty_show = 0" class="fr" src="../../assets/image/Popup_Close_icon@2x.png" alt />
				</p>
				<div ref="txt" class="txt" @mousewheel.stop></div>
				<button v-if="treaty_type == 0" @click="treaty_show = 0">同意并继续</button>
				<button v-if="treaty_type == 1" @click="treaty_show = 0" style="background:#999999; margin-right:20px">取消</button>
				<button v-if="treaty_type == 1" @click="GoLogons()">同意并继续</button>
			</div>
		</div>
		<!-- <div class="bgw">
      <publicBottom></publicBottom>
    </div>-->
	</div>
</template>
<script>
	import landHeader from "@/components/land/land_header";
	// import publicBottom from "@/components/public/public_bottom";
	import {
		login
	} from "@/request/land";
	import {
		getSysconfs
	} from "@/request/public";
	import Vue from 'vue'
	export default {
		components: {
			landHeader
			// publicBottom
		},
		data() {
			return {
				treaty_show: 0, //协议显示
				treaty_type: 0, // 协议 类型
				treaty_txt: "111111111", //协议类型
				phone: "", //手机号
				phoneErr: "",
				password: "", //密码
				passwordErr: "",
				fuwutiaokuan: "",
				yinsitiaokuan: ""
			};
		},
		created() {
			localStorage.clear();
			console.log(this.$token);
			this.treaty_show = this.$route.query.treaty_show;
			if (this.treaty_show == 1) {
				this.treaty_type = 1;
			}
			getSysconfs({
				token: this.$token
			}).then(res => {
				if (res.code == 1) {
					this.$refs.txt.innerHTML = res.data.fuwutiaokuan;
					this.fuwutiaokuan = res.data.fuwutiaokuan;
					this.yinsitiaokuan = res.data.yinsitiaokuan;
				}
			});
		},
		methods: {
			sendChecking() {
				if (this.phone == "") {
					this.phoneErr = "请输入手机号";
					return false;
				}
				//  else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
				//   this.phoneErr = "手机号格式不正确";
				//   return false;
				// }
				else if (this.password == "") {
					this.passwordErr = "请输入密码";
					return false;
				} else if (this.password.length < 6) {
					this.passwordErr = "密码格式不正确";
					return false;
				} else {
					this.send();
				}
			},
			send() {
				login({
					user_login: this.phone,
					user_pass: this.password
				}).then(res => {
					let Athis = this;
					if (res.code === 1) {
						// 登录
						let token = res.data.token;
						Vue.prototype.$token=token;
						localStorage.setItem("lzyyToken", token);
						this.$user.token = token;
						this.$user.state = 1;
						// 保存号码
						let phone = this.phone;
						localStorage.setItem("lzyyPhone", phone);
						this.$message({
							message: "登录成功",
							type: "success"
						});
						Vue.prototype.imageUrl ="http://hlwyaodian.jzbwlkj.com/api/file/upload?token=" + token;
						console.log(res.data.cert_type);
						if (res.data.cert_type === 0) {
							setTimeout(function() {
								Athis.$router.push("/?isRenderSocket=true");
							}, 1000);
						} else if (res.data.cert_type === 1) {
							setTimeout(function() {
								Athis.$router.push({
									path: "/home/doctor/index"
								});
							}, 1000);
						} else if (res.data.cert_type === 2) {
							setTimeout(function() {
								Athis.$router.push({
									path: "/home/expert/index"
								});
							}, 1000);
						} else if (res.data.cert_type === 3) {
							setTimeout(function() {
								Athis.$router.push({
									path: "/home/pharmacist/index"
								});
							}, 1000);
						} else if (res.data.cert_type === 4) {
							setTimeout(function() {
								Athis.$router.push({
									path: "/home/deployment/index"
								});
							}, 1000);
						} else if (res.data.cert_type === 5) {
							setTimeout(function() {
								Athis.$router.push({
									path: "/home/check/index"
								});
							}, 1000);
						}
					} else {
						this.$message.error("手机号或者密码错误");
					}
				});
			},
			// 去注册
			GoLogons() {
				this.$router.replace({
					path: "/logon"
				});
			},
			GoLogon() {
				this.treaty_type = 1;
				this.treaty_show = 1;
			},
			treatyTab() {
				this.treaty_type = 0;
				this.treaty_show = 1;
			}
		}
	};
</script>
<style lang="less" scoped>
	@import "../../assets/less/land";
</style>
