import { post } from './http'

// 登录  
export const login = data => post('api/public/login', data)

// 注册  
export const register = data => post('api/public/register', data)

// 找回密码
export const submitForgetPass = data => post('api/public/submitForgetPass', data)

// 注册  
// export const sendSms = data => get('api/public/register', data)

// // 注册  
// export const sendSms = data => get('api/public/register', data)

// // 注册  
// export const sendSms = data => get('api/public/register', data)

// // 注册  
// export const sendSms = data => get('api/public/register', data)